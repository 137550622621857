import { Customer } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { setActiveEcomCustomerByExternalIdAsync } from '../../actions/farmlands-set-active-ecom-customer-by-external-id-calls.action';
import { ICustomerAccountData } from '../../modules/farmlands-header/farmlands-header.data';
import { setActiveAccountNumber } from '../../modules/farmlands-header/utilities/multi-account';

const charLimit = 30;

export const renderActiveAccount = (account: ICustomerAccountData, isOnly: boolean) => {
    const overLimit = account?.CustomerName?.length > charLimit;
    const displayName = overLimit ? `${account?.CustomerName?.substr(0, charLimit - 3)?.trim()}...` : account?.CustomerName;

    return (
        <div className={`ms-header__account-item ms-header__active-account ${isOnly ? 'ms-header__active-account--only' : ''}`}>
            <div className='ms-header__account-info-wrapper'>
                <div className='ms-header__account-info-dropdown'>
                    <span className='ms-header__account-name-dropdown'>{displayName}</span>
                    <span className='ms-header__account-number-dropdown'>
                        Account number: <b>{account?.AccountNumber}</b>
                    </span>
                </div>
            </div>
            <span className='ms-header__active-account-text'>
                <span className='ms-header__active-circle'></span>ACTIVE
            </span>
        </div>
    );
};

export const renderAccount = (
    account: ICustomerAccountData,
    switchProps: { context: any; externalId: any; accountNo: any },
    toggleRefreshModal: () => void
) => {
    const overLimit = account?.CustomerName?.length > charLimit;
    const displayName = overLimit ? `${account?.CustomerName?.substr(0, charLimit - 3)?.trim()}...` : account?.CustomerName;

    async function updateAccount(accountNumber: string) {
        await setActiveEcomCustomerByExternalIdAsync({
            context: switchProps.context,
            customerAccountNumber: accountNumber
        }).then((data) => {
            if (data.Code === '200') {
                const accountDropdown = document.getElementById('myprofilePopover');
                if (accountDropdown) {
                    accountDropdown.style.display = 'none';
                }
                const accountSwitchModal = document.getElementById('AccountSwitchModal');
                if (accountSwitchModal) {
                    accountSwitchModal.style.display = 'none';
                }
                setActiveAccountNumber(accountNumber);
                toggleRefreshModal();
                if (window) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            }
        });
    }

    return (
        <div className='ms-header__account-item'>
            <div className='ms-header__account-info-wrapper'>
                <div className='ms-header__account-info-dropdown'>
                    <span className='ms-header__account-name-dropdown'>{displayName}</span>
                    <span className='ms-header__account-number-dropdown'>
                        Account number: <b>{account?.AccountNumber}</b>
                    </span>
                </div>
            </div>
            <div
                className='ms-header__select-account'
                onClick={() => {
                    updateAccount(account?.AccountNumber);
                }}
            >
                Select
            </div>
        </div>
    );
};

export const getAccountList = (
    customer: Customer | undefined,
    switchProps: any,
    toggleRefreshModal: () => void,
    customerAccounts?: ICustomerAccountData[],
    displayName?: string
): React.ReactNode[] | undefined => {
    return customer && displayName && customerAccounts && customerAccounts.length > 0
        ? customerAccounts
              ?.slice(0, 5)
              ?.map((account) =>
                  account?.IsActive
                      ? renderActiveAccount(account, customerAccounts.length === 1)
                      : renderAccount(account, switchProps, toggleRefreshModal)
              )
        : undefined;
};
