import { Button, Modal, ModalBody } from '@msdyn365-commerce-modules/utilities';
import React from 'react';
import { ICustomerAccountData } from '../../modules/farmlands-header/farmlands-header.data';
import { renderAccount, renderActiveAccount } from './multi-account';

interface IAccountSwitchModal {
    toggleAccountModal: (e: boolean) => void;
    toggleRefreshModal: () => void;
    customerAccounts?: ICustomerAccountData[];
    isAccountModalOpen: boolean;
    switchAccountProps: any;
}

export interface IRefreshPageModal {
    toggleRefreshModal: () => void;
    isModalOpen: boolean;
}

export const AccountSwitchModal = (props: IAccountSwitchModal) => {
    const _renderAccountModalContent = () => {
        return (
            <div className='farmlands-account-modal'>
                <Button
                    className='msc-modal__close-button'
                    id='msc-modal__close-acount-modal-button'
                    onClick={() => props.toggleAccountModal(false)}
                ></Button>
                <span className='material-icons-outlined farmlands-account-modal__account-icon'>person</span>
                <h1 className='farmlands-account-modal__account-heading'>Select your Account</h1>
                {props.customerAccounts?.map((account: any) =>
                    account?.IsActive
                        ? renderActiveAccount(account, false)
                        : renderAccount(account, props.switchAccountProps, props.toggleRefreshModal)
                )}
            </div>
        );
    };

    return (
        <Modal isOpen={props.isAccountModalOpen} id='AccountSwitchModal' toggle={() => props.toggleAccountModal(false)}>
            <ModalBody>{_renderAccountModalContent()}</ModalBody>
        </Modal>
    );
};

export const RefreshPageModal = (props: IRefreshPageModal) => {
    const _renderModalContent = () => {
        return (
            <div className='RefreshPagesModal'>
                <h1 className='RefreshPagesModal__header'>Switching your Shareholder Account.</h1>
                <p className='RefreshPagesModal__text'>Please refresh any open Farmlands pages to view your Shareholder Account.</p>
            </div>
        );
    };

    return (
        <Modal isOpen={props.isModalOpen}>
            <ModalBody>{_renderModalContent()}</ModalBody>
        </Modal>
    );
};
